import React, {Component} from 'react'

function epochToTime(time){
    var timeShift = parseInt(process.env.REACT_APP_TIME_SHIFT)*3600 //hours -> seconds
    var d = new Date((time+timeShift)*1000)
    //var d = new Date(time*1000)
    var y = d.getFullYear().toString().substr(-2)
    var month = d.getMonth() + 1
    var day = d.getDate()
    var h = d.getHours()
    var m = d.getMinutes()
    if(m<10){m="0"+m.toString()}
    return day+"."+month+"."+y+" "+h+":"+m
}

async function setRoute(component, props){
    if(props.route.length>0){
        var id = props.id
        var trips = []
        
        var route = []
        if(!id){
            route = props.route[0].Route
        }else{
            for(var i=0; i<props.route.length; i++){
                if(props.route[i].OrderId==id){
                    route = props.route[i].Route
                    break
                }
            }
        }
        
        for(var i=0; i<route.length; i++){
            var arrT = epochToTime(route[i].Time)
            trips.push({EventType: route[i].EventType,
                        OrderId: route[i].OrderId,
                        ArrivalTime: arrT,
                        Address: route[i].Address,
                        Sequence: route[i].Sequence })
        }
        component.state.route = trips
        component.state.Id = id
    }
}


class TripList extends Component {
    state = {
        route: [],
        Id: ""
    }

    constructor(props) {
        super(props)
        //console.log("props")
        //console.log(props)
        setRoute(this, props)
    }

    async componentWillReceiveProps(nextProps) {
        //console.log("nextProps")
        //console.log(nextProps)
        
        if(nextProps.route.length>0){
            setRoute(this, nextProps)
        }
    }

    draw() {}

    render() {
        //separate route
        if(this.state.Id){
            return (
                <div class='tripList'>
                    {this.state.route.map((r) =>
                    <div class='trip2'>
                        <div class='tripType'>{r.EventType}</div>
                        <div class='tripAddr'><span>{r.Address}</span></div>
                    </div>
                    )}
                </div>
            )
        }
        //combined route
        else{
            return (
                <div class='tripList'>
                    {this.state.route.map((r) =>
                    <div class='trip'>
                        <div class='tripSequence'>[{r.Sequence}]</div>
                        <div class='tripType'>{r.EventType}</div>
                        <div class='tripId'>{r.OrderId}</div>
                        <div class='tripTime'>{r.ArrivalTime}</div>
                        <div class='tripAddr'><span>{r.Address}</span></div>
                    </div>
                    )}
                </div>
            )
        }
    }
}

export default TripList
