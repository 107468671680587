import React, {Component} from 'react'


class SearchBox extends Component {
    state = {
        SearchValue: "",
        ErrorMessage: ""
    }

    constructor(props) {
        super(props)
        //console.log("props")
        //console.log(props)
    }

    async componentWillReceiveProps(nextProps) {
        //console.log("nextProps")
        //console.log(nextProps)
    }

    searchValueChange = e => {
        this.setState({
            ErrorMessage: "",
            SearchValue: e.target.value
        })
    }

    async onSearch() {
        this.setState({ErrorMessage: ""})
        if(!this.state.SearchValue){
            this.setState({ErrorMessage: "Search field is empty"})
            return
        }

        const requestOptions = {
            headers: {
                "Content-Type": "application/json",
                "X-API-Key": process.env.REACT_APP_MERGING_APIKEY
            }
        }
        var id = this.state.SearchValue
        var merging_addr = process.env.REACT_APP_MERGING_API + '/api/tripmerging/getTripByOrderId/' + id
        var response = await fetch(merging_addr, requestOptions)
        await response.json().then((data) => {
            console.log(data)
            if(data.Id){
                this.props.setRoute(data)
                this.props.closeBox()
                return
            }
            this.setState({ErrorMessage: "Order not found"})
        })
    }


    draw() {}

    render() {
        return (
            <div class='searchBoxDiv'>
                <img class="closeIcon" src="close-icon.png" alt="close"
                    onClick={() => this.props.closeBox()} />

                <div class='searchBox'>
                    <div class='searchTitleDiv'>
                        <span>Search order</span>
                    </div>
                    <div class='searchFieldDiv'>
                        <label>Order ID</label>
                        <input maxlength="16" onChange={(e) => this.searchValueChange(e)} />
                    </div>
                    <div class='searchButtonDiv'>
                        <button onClick={() => this.onSearch()}>
                            Search
                        </button>
                    </div>
                    <div class='searchErrorDiv'>
                        <span>{this.state.ErrorMessage}</span>
                    </div>

                </div>
            </div>
        )
    }
}

export default SearchBox
