import React, {Component} from 'react'
import L from 'leaflet'
import {MapContainer, TileLayer, Marker, Polyline, useMap, Tooltip} from 'react-leaflet'
import {format} from 'react-string-format'

import markerUp from "../assets/marker-green-up.png"
import markerDown from "../assets/marker-red-down.png"

var pickMarker = L.icon({
    iconUrl: markerUp,
    iconSize: [process.env.REACT_APP_MARKER_W, process.env.REACT_APP_MARKER_H],
    iconAnchor: [12.5, 41],
    popupAnchor: [0, -41]
})

var deliMarker = L.icon({
    iconUrl: markerDown,
    iconSize: [process.env.REACT_APP_MARKER_W, process.env.REACT_APP_MARKER_H],
    iconAnchor: [12.5, 41],
    popupAnchor: [0, -41]
})


function AutoZoom(data){
    const map = useMap()
    var points = data["points"]
    if(points.length){
        var markers = []
        for(var i=0; i<points.length; i++){
            var p = points[i]
            markers.push([p.Lat,p.Lon])
        }
        map.fitBounds(markers)
    }
    return null
}


class LeafletMap extends Component {
    state = {
        trips: []
    }

    constructor(props) {
        super(props)
    }

    async componentWillReceiveProps(nextProps) {
        //console.log("nextProps")
        //console.log(nextProps)
        
        // get combined route
        if(nextProps.route.length>0){
            this.setState(state => ({trips: nextProps.route}))
        }
    }

    draw() {}

    render() {
        //console.log("trips")
        //console.log(this.state.trips)
        
        var trips = this.state.trips
        var pickPoints = []
        var deliPoints = []
        var lines = []
        var accRoute = []
        var allPoints = []

        //find sequence-numbers for multiple points at same location
        var coordsToSequence = {}
        for(var i=0; i<trips.length; i++){
            var trip = trips[i].Route
            for(var j=0; j<trip.length; j++){
                var c = trip[j].Lat+","+trip[j].Lon
                var t = trip[j].EventType
                if(!coordsToSequence[c]){
                    coordsToSequence[c] = {"pickup": [], "dropoff": []}
                }
                coordsToSequence[c][t].push(trip[j].Sequence)
                coordsToSequence[c][t].sort()
            }
        }

        //make sequence-strings for tooltips to show
        var coordsToSeqStr = {}
        if(Object.keys(coordsToSequence).length>0){
            var coords = Object.keys(coordsToSequence)
            coords.forEach(c => {
                var co = coordsToSequence[c]
                if(co.pickup.length>0 && co.dropoff.length>0){
                    coordsToSeqStr[c] = "p:"+co.pickup.join()+" d:"+co.dropoff.join()
                    //coordsToSeqStr[c] = co.pickup.join()+" / "+co.dropoff.join()
                }else{
                    coordsToSeqStr[c] = co.pickup.join()+co.dropoff.join()
                }
            })
        }

        for(var i=0; i<trips.length; i++){
            var trip = trips[i].Route
            for(var j=0; j<trip.length; j++){
                var c = trip[j].Lat+","+trip[j].Lon
                var event = {EventType: trip[j].EventType,
                             OrderId: trip[j].OrderId,
                             Pos: [trip[j].Lat, trip[j].Lon],
                             Name: trip[j].Name,
                             Sequence: coordsToSeqStr[c]} //trip[j].Sequence}
                if(trip[j].EventType == "pickup"){
                    pickPoints.push(event)
                }else if(trip[j].EventType == "dropoff"){
                    deliPoints.push(event)
                }
            }
            accRoute = trips[i].AccurateRoute
            var path = []
            for(var j=0; j<accRoute.length; j++){
                path.push([accRoute[j].Lat, accRoute[j].Lon])
                allPoints.push(accRoute[j])
            }
            lines.push({path: path, color: trips[i].Color})
        }
        //console.log("lines")
        //console.log(lines)
        
        var mapPosition = process.env.REACT_APP_DEFAULT_COORDS.split(",").map(x=>+x)
        var defaultMapZoom = process.env.REACT_APP_DEFAULT_ZOOM
        var mapZoom = defaultMapZoom
        if(lines.length){
            mapZoom = process.env.REACT_APP_DEFAULT_ZOOMED
        }
        
        var attr = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        var url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        
        return (
            <MapContainer className='map'
                          center={mapPosition}
                          zoom={mapZoom}
                          minZoom={6}
                          maxZoom={17}
                          zoomSnap={0.5}
                          scrollWheelZoom={true}
                          zoomControl={false}
                          attributionControl={false}
                          ref={this.mapInit}>

                <AutoZoom points={allPoints} />

                <TileLayer attribution={attr} url={url} />{
                    pickPoints.map((p) =>
                        <Marker key={p.OrderId + p.EventType}
                                position={p.Pos}
                                icon={pickMarker}>
                            <Tooltip permanent={true}>
                                {p.Sequence}
                            </Tooltip>
                        </Marker>)}

                <TileLayer attribution={attr} url={url} />{
                    deliPoints.map((p) =>
                        <Marker key={p.OrderId + p.EventType}
                                position={p.Pos}
                                icon={deliMarker}>
                            <Tooltip permanent={true}>
                                {p.Sequence}
                            </Tooltip>
                        </Marker>)}

                <TileLayer attribution={attr} url={url} />{
                    lines.map((line) => <Polyline positions={line.path} color={line.color} />)}

            </MapContainer>
        )
    }
}

export default LeafletMap
