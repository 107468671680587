import React, {Component} from 'react'


class StatsMain extends Component {
    state = {
        "mergedDistance": 0.0,
        "mergedTime": 0.0,
        "mergedCost": 0.0,
        "difDistance": 0.0,
        "difTime": 0.0,
        "difCost": 0.0,
        "difDistancePercentage": 0.0,
        "difTimePercentage": 0.0,
        "difCostPercentage": 0.0
    }

    constructor(props) {
        super(props)
    }

    async componentWillReceiveProps(nextProps) {
        //console.log("nextProps")
        //console.log(nextProps)
        
        if(!nextProps.requestresponse.Orders){return}
        
        var orders = nextProps.requestresponse.Orders
        var total_service_time = 0
        for(var i=0; i<orders.length; i++){
            total_service_time += orders[i].PickupServiceTimeInSeconds
            total_service_time += orders[i].DropoffServiceTimeInSeconds
        }
        
        var stats = nextProps.requestresponse.MergedTrip.TripStatistics
        
        var new_distance = stats.MergedDistanceWithCustomers/1000
        var nonmerged_d = stats.NonMergedDistanceWithCustomers/1000
        var d_dif = new_distance-nonmerged_d
        var d_dif_p = 0
        if(nonmerged_d > 0){ d_dif_p = d_dif/nonmerged_d*100 }
        
        var new_time =
            (stats.MergedDrivingTimeWithCustomers+total_service_time)/60
        var nonmerged_t =
            (stats.NonMergedDrivingTimeWithCustomers+total_service_time)/60
        var t_dif = new_time-nonmerged_t
        var t_dif_p = 0
        if(nonmerged_d > 0){ t_dif_p = t_dif/nonmerged_t*100 }
        
        this.setState(state => ({mergedDistance: new_distance,
                                 mergedTime: new_time,
                                 difDistance: d_dif,
                                 difTime: t_dif,
                                 difDistancePercentage: d_dif_p,
                                 difTimePercentage: t_dif_p}))
    }

    draw() {}

    render() {
        // <div id='mainCostIconDiv'>
        //             <span className="tooltip"><img class="" src="money-icon.png" alt="m" />
        //                 <span className="tooltiptext">
        //                     Cost
        //                 </span></span>
        //         </div>
        // <div id='mainCostDifDiv'>
        //             <span class={this.state.difCost < 0 && 'diffColorNeg'}>
        //                 &#916; {this.state.difCost.toFixed(1)} €
        //                 {this.state.difCost < 0 && ' (' +this.state.difCostPercentage.toFixed(1) +' %)'}
        //             </span>
        //         </div>
        // <div id='mainCostAmountDiv'>
        //             <span>
        //                 {this.state.mergedCost.toFixed(1)} €
        //             </span>
        //         </div>
        return (
            <div id='mainStatsDiv'>
                <div id='mainTimeIconDiv'>
                    <span className="tooltip"><img class="" src="time-icon.png" alt="t" />
                        <span className="tooltiptext">
                            Route time
                        </span></span>
                </div>
                <div id='mainRouteIconDiv'>
                    <span className="tooltip"><img class="" src="route-icon.png" alt="r" />
                        <span className="tooltiptext">
                            Route length
                        </span></span>
                </div>
                
                <div id='mainTimeDifDiv'>
                    <span class={this.state.difTime < 0 && 'diffColorNeg'}>
                        &#916; {this.state.difTime.toFixed(0)} min
                        {this.state.difTime < 0 && ' (' +this.state.difTimePercentage.toFixed(1) +' %)'}
                    </span>
                </div>
                <div id='mainRouteDifDiv'>
                    <span class={this.state.difDistance < 0 && 'diffColorNeg'}>
                        &#916; {this.state.difDistance.toFixed(1)} km
                        {this.state.difDistance < 0 && ' (' +this.state.difDistancePercentage.toFixed(1) +' %)'}
                    </span>
                </div>
                
                <div id='mainTimeAmountDiv'>
                    <span>
                        {this.state.mergedTime.toFixed(0)} min
                    </span>
                </div>
                <div id='mainRouteAmountDiv'>
                    <span>
                        {this.state.mergedDistance.toFixed(1)} km
                    </span>
                </div>
            </div>
        )
    }
}

export default StatsMain
