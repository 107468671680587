import React, {Component} from 'react'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'

//import Header from './components/header'
import Listing from './components/listing'
import Login from './components/login'

import './assets/basestyle.css'
import './assets/login.css'

class App extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }

    state = {
        Authorized: false
    }

    constructor(props) {
        super(props)
        const { cookies } = props
    }

    componentDidMount() {
        const { cookies } = this.props
        
        var token = cookies.get("token")
        if(token==process.env.REACT_APP_AUTH){
            this.setState({Authorized: true})
        }else{
            cookies.set('token', "", { path: '/' })
            this.setState({Authorized: false})
        }
    }

    onNewAuthorized = (isAuthorized, token) => {
        const { cookies } = this.props
        cookies.set('token', token.toString(), { path: '/' })
        this.setState({Authorized: isAuthorized})
    }

    render() {
        if(this.state.Authorized){
            return (
                <div id='app'>
                    <Listing id='listing'/>
                </div>
            )
        }else{
            return (
                <div id='app'>
                    <Login onNewAuthorized={this.onNewAuthorized} />
                </div>
            )
        }
    }
}

export default withCookies(App)
