import React, {Component} from 'react'
import sha256 from 'crypto-js/sha256'


class Login extends Component{
    state = {
        Username: "taksi",
        Password: "",
        Error: ""
    }

    async onLogin() {
        
    }

    usernameChange = e => {
        this.setState({Error: ""})
        this.setState({Username: e.target.value})
    }

    passwordChange = e => {
        this.setState({Error: ""})
        this.setState({Password: e.target.value})
    }

    onFormSubmit = e => {
        e.preventDefault()
        var u = this.state.Username
        if(u.length<=0){
            this.setState({Error: "username missing"})
            return
        }
        var p = this.state.Password
        if(p.length<=0){
            this.setState({Error: "password missing"})
            return
        }
        
        var digest = sha256(u+p)
        //console.log("digest "+digest)
        if(digest==process.env.REACT_APP_AUTH){
            this.props.onNewAuthorized(true , digest)
        }else{
            this.setState({Error: "faulty credentials"})
        }
    }

    render() {
        return(
            <div id='loginOuterDiv'>
            <form id="loginForm" onSubmit={this.onFormSubmit} />
            <div id='loginInnerDiv'>
                <h1>Login</h1>
                <div id="formDiv">
                    <label>Username</label>
                    <input type="text" form="loginForm"
                        value={this.state.Username}
                        maxLength="12"
                        onChange={e => this.usernameChange(e)}/>
                    <label>Password</label>
                    <input type="password" form="loginForm"
                        maxLength="16"
                        onChange={e => this.passwordChange(e)}/>
                </div>
                <div id='loginButtonDiv'>
                    <input class="button"
                        type="submit" form="loginForm"
                        value="Log in" />
                </div>
                <div id='loginErrorDiv'>
                    <span class="error">{this.state.Error}</span>
                </div>
            </div>
            </div>
        )
    }
}

export default Login
