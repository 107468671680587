import React, {Component} from 'react'


function epochToDayTime(time){
    var timeShift = parseInt(process.env.REACT_APP_TIME_SHIFT)*3600 //hours -> seconds
    var d = new Date((time+timeShift)*1000)
    //var d = new Date(time*1000)
    var h = d.getHours()
    var m = d.getMinutes()
    if(m<10){m="0"+m.toString()}
    return h+":"+m
}

async function setStats(component, props){
    if(props.id){
        var id = props.id
        
        //get total service time, get requested time
        var orders = props.requestresponse.Orders
        var total_service_time = 0
        var reqTime = 0
        var apptTime = 0
        for(var i=0; i<orders.length; i++){
            if(orders[i].Id==id){
                total_service_time += orders[i].PickupServiceTimeInSeconds
                total_service_time += orders[i].DropoffServiceTimeInSeconds
                reqTime = orders[i].RequestedPickupTime
                apptTime = orders[i].AppointmentTime
                break
            }
        }
        
        //get times and distances, get pickup/arrival time, get dropoff/departure time
        var trip = props.requestresponse.MergedTrip.Trip
        var start_time = 0
        var end_time = 0
        var start_distance = 0
        var end_distance = 0
        var pickArrTime = 0
        var dropDepTime = 0
        
        for(var i=0; i<trip.length; i++){
            var r = trip[i]
            if(r.OrderId==id && r.EventType=="pickup"){
                start_time = r.ArrivalTime
                start_distance = r.CumulativeDistance
                pickArrTime = r.ArrivalTime
            }else if(r.OrderId==id && r.EventType=="dropoff"){
                end_time = r.DepartureTime
                end_distance = r.CumulativeDistance
                dropDepTime = r.DepartureTime
            }
        }
        var new_time = 0
        if(start_time>0 && end_time>start_time){
            new_time = end_time-start_time
        }
        var new_distance = 0
        if(end_distance>start_distance){
            new_distance = end_distance-start_distance
        }

        //set state values
        if(props.separateRoutes.length>0){
            for(var i=0; i<props.separateRoutes.length; i++){
                var r = props.separateRoutes[i]
                if(r.OrderId==id){
                    component.state.Distance=r.Distance/1000
                    component.state.Time=
                        (r.Time+total_service_time)/60
                    component.state.Cost=r.Cost
                    component.state.NewTime=new_time/60
                    component.state.NewDistance=new_distance/1000
                    component.state.ReqPickup=epochToDayTime(reqTime)
                    component.state.ReqPickupDif=(pickArrTime-reqTime)/60
                    if(apptTime!=null){
                        component.state.AppointmentTime=epochToDayTime(apptTime)
                        component.state.AppointmentDif=(dropDepTime-apptTime)/60
                    }else{
                        component.state.AppointmentTime="-"
                        component.state.AppointmentDif=0
                    }
                }
            }
        }
    }
}


class StatsSeparate extends Component {
    state = {
        Time: 0.0,
        Distance: 0.0,
        Cost: 0.0,
        NewTime: 0.0,
        NewDistance: 0.0,
        NewCost: 0.0,
        ReqPickup: "",
        ReqPickupDif: 0,
        AppointmentTime: "",
        AppointmentDif: 0

    }

    constructor(props) {
        super(props)
        setStats(this, props)
    }

    async componentWillReceiveProps(nextProps) {
        //console.log("nextProps")
        //console.log(nextProps)
        
        setStats(this, nextProps)
    }

    draw() {}

    render() {
        // <div class='sepCostIconDiv'>
        //             <span className="tooltip"><img class="" src="money-icon.png" alt="m" />
        //                 <span className="tooltiptext">
        //                     Cost
        //                 </span></span>
        //         </div>
        // <div class='sepCostDiv'>
        //             <span>
        //                 {this.state.Cost.toFixed(1)} €
        //             </span>
        //         </div>
        return (
            <div class='sepStats'>
                <div class='sepTimeIconDiv'>
                    <span className="tooltip"><img class="" src="time-icon.png" alt="time" />
                        <span className="tooltiptext">
                            Route time
                        </span></span>
                </div>
                <div class='sepWaitIconDiv'>
                    <span className="tooltip"><img class="" src="waiting-icon.png" alt="pickup" />
                        <span className="tooltiptext">
                            Pickup time
                        </span></span>
                </div>
                <div class='sepApptIconDiv'>
                    <span className="tooltip"><img class="" src="appointment-icon.png" alt="appointment" />
                        <span className="tooltiptext">
                            Appointment time
                        </span></span>
                </div>
                <div class='sepRouteIconDiv'>
                    <span className="tooltip"><img class="" src="route-icon.png" alt="length" />
                        <span className="tooltiptext">
                            Route length
                        </span></span>
                </div>
                
                <div class='sepTimeDiv'>
                    <span>
                        {this.state.Time.toFixed(0)} min
                        <span class="arrow">&#8594;</span>
                        {this.state.NewTime.toFixed(0)} min
                    </span>
                </div>
                <div class='sepWaitDiv'>
                    <span>
                        {this.state.ReqPickupDif<0 &&
                            <span class="plusminus">&#8211;</span>
                        }
                        {this.state.ReqPickupDif>=0 &&
                            <span class="plusminus">+</span>
                        }
                        <span class="pr10">{Math.abs(this.state.ReqPickupDif.toFixed(0))} min</span>
                        ({this.state.ReqPickup})
                    </span>
                </div>
                <div class='sepApptDiv'>
                    <span>
                        {this.state.AppointmentDif<0 &&
                            <span class="plusminus">&#8211;</span>
                        }
                        {this.state.AppointmentDif>=0 &&
                            <span class="plusminus">+</span>
                        }
                        <span class="pr10">{Math.abs(this.state.AppointmentDif.toFixed(0))} min</span>
                        ({this.state.AppointmentTime})
                    </span>
                </div>
                <div class='sepRouteDiv'>
                    <span>
                        {this.state.Distance.toFixed(1)} km
                        <span class="arrow">&#8594;</span>
                        {this.state.NewDistance.toFixed(1)} km
                    </span>
                </div>
            </div>
        )
    }
}

export default StatsSeparate
