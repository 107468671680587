import React, {Component} from 'react'
import {format} from 'react-string-format'

import examples from "../assets/examples.json"
import timewindows from "../assets/timewindows.json"

import LeafletMap from './leafletmap'
import StatsMain from './statsmain'
import TripList from './triplist'
import StatsSeparate from './statsseparate'
import SearchBox from './searchbox'
//import DetailsBox from './detailsbox'


function epochNow(){
    return Math.round(Date.now()/1000)
}

function epochToTime(time){
    var timeShift = parseInt(process.env.REACT_APP_TIME_SHIFT)*3600 //hours -> seconds
    var d = new Date((time+timeShift)*1000)
    //var d = new Date(time*1000)
    var y = d.getFullYear().toString().substr(-2)
    var month = d.getMonth() + 1
    var day = d.getDate()
    var h = d.getHours()
    var m = d.getMinutes()
    if(m<10){m="0"+m.toString()}
    return day+"."+month+"."+y+" "+h+":"+m
}


export default class Form extends Component {
    constructor(props) {
        super(props)
    }

    state = {
        mainRoute: [],
        separateRoutes: [],
        detailsId: "",
        RequestResponse: {},
        RoutesList: [],
        SearchBoxVisible: 0,
        TWKeys: []
    }

    async onTWChange(key) {
        if(key == "novalue") return
        if(timewindows[key]){
            this.setRouteChoices(timewindows[key])
        }else{
            console.log("key not found")
        }
    }

    async onRouteChange(id) {
        //console.log("onRouteChange id "+id)
        if(id == "novalue") return
        
        const requestOptions = {
            headers: {
                "Content-Type": "application/json",
                "X-API-Key": process.env.REACT_APP_MERGING_APIKEY
            }
        }
        var merging_addr = process.env.REACT_APP_MERGING_API + '/api/tripmerging/trips/' + id
        var response = await fetch(merging_addr, requestOptions)
        await response.json().then((data) => {
            //console.log(data)
            this.setRoutes(data)
        })
    }

    // async onDetailsClick(id) {
    //     if(!id) return
    //     this.setState({detailsId: id})
    // }

    // onCloseDetails = (data) => {
    //     this.setState({detailsId: ""})
    // }

    async onSearchRouteClick() {
        this.setState({SearchBoxVisible: 1})
    }

    onCloseSearchBoxClick = () => {
        this.setState({SearchBoxVisible: 0})
    }

    onSearchBoxSetRoute = (data) => {
        this.setRoutes(data)
        //twkeys is emptied and created again after search
        //that drop-select selection return to first choice
        this.setState({
            RoutesList: [],
            TWKeys: []
        })
        this.setTWChoices()
    }

    async getAccurateRoute(route){
        var osrm_addr_temp = process.env.REACT_APP_OSRM_API + '/route/v1/driving/'
        for(var i = 0; i < route.length; i++){
            if(i > 0){ osrm_addr_temp += ';' }
            osrm_addr_temp += route[i].Lon + "," + route[i].Lat
        }
        osrm_addr_temp += '?overview=simplified&geometries=geojson'

        var accRoute = []
        var response = await fetch(osrm_addr_temp)
        await response.json().then((data) => {
            if(data['code'] == "Ok"){
                var coords = data['routes'][0]['geometry']['coordinates']
                for(var i = 0; i < coords.length; i++){
                    accRoute.push(
                        {"Lat": coords[i][1], "Lon": coords[i][0]}
                    )
                }
            }
        })
        return accRoute
}

    async setTWChoices(tw){
        var twKeys = Object.keys(timewindows)
        this.setState({TWKeys: twKeys})
    }

    async setRouteChoices(tw){
        var tNow = epochNow()
        var startTime = tNow - tw.TimeMinus
        var endTime = tNow + tw.TimePlus

        const requestOptions = {
            headers: {
                "Content-Type": "application/json",
                "X-API-Key": process.env.REACT_APP_MERGING_APIKEY
            }
        }
        var merging_addr = process.env.REACT_APP_MERGING_API
            + '/api/tripmerging/index?startTime=' + startTime + '&endTime=' + endTime
        
        var response = await fetch(merging_addr, requestOptions)
        await response.json().then((data) => {
            //console.log(data)
            this.setState(state => ({RoutesList: data}))
        })
        return
    }

    async setRoutes(route){
        var colors = process.env.REACT_APP_ROUTE_COLORS.split(",")
        
        //make idToSequence dict
        var idToSequence = {}
        if(route.MergedTrip.Trip){
            var trip = route.MergedTrip.Trip
            var seq = 1
            for(var i=0; i<trip.length; i++){
                var tripEvent = trip[i]
                if(tripEvent.EventType == "pickup"){
                    idToSequence[tripEvent.OrderId] = seq
                    seq++
                }
            }
        }
        
        // make attrId-to-attrName dict
        var attrToName = {}
        if(route.RequestSettings){
            // TODO: commented out because AttributeCapacities/names causes obscurity
            //var attrCaps = route.RequestSettings.AttributeCapacities
            //for(var i=0; i<attrCaps.length; i++){
            //    attrToName[''+attrCaps[i].Id]=attrCaps[i].Name
            //}
            var attrNames = route.RequestSettings.AttributeNames
            for(var i=0; i<attrNames.length; i++){
                if(!attrToName[''+attrNames[i].Id]){
                    attrToName[''+attrNames[i].Id]=attrNames[i].Name
                }
            }
        }
        
        //set main (combined) route
        var trips_main = []
        if(route.MergedTrip.Trip){
            var trip = route.MergedTrip.Trip
            var routeTemp = []
            for(var i=0; i<trip.length; i++){
                var event = {
                    EventType: trip[i].EventType,
                    OrderId: trip[i].OrderId,
                    Lat: trip[i].LocationLatitude,
                    Lon: trip[i].LocationLongitude,
                    Sequence: idToSequence[trip[i].OrderId],
                    Time: trip[i].ArrivalTime,
                    Address: trip[i].Address
                }
                routeTemp.push(event)
            }
            var accRoute = await this.getAccurateRoute(routeTemp)
            
            var color = "gray"
            if(colors.length>0) color=colors[0]
            
            var trip_new = {
                Route: routeTemp,
                AccurateRoute: accRoute,
                Distance: 0,
                Time: 0,
                Cost: 0,
                Color: color,
                OrderId: ""
            }
            trips_main.push(trip_new)
        }
        
        //set separate routes
        var trips_sep = []
        if(route.Orders){
            var orders = route.Orders
            for(var i=0; i<orders.length; i++){
                var order = orders[i]
                var routeTemp = []
                var event = {
                    EventType: "pickup",
                    OrderId: order.Id,
                    Lat: order.PickupLocationLat,
                    Lon: order.PickupLocationLong,
                    Sequence: idToSequence[order.Id],
                    Time: 0,
                    Address: order.PickupAddress
                }
                routeTemp.push(event)
                
                event = {
                    EventType: "dropoff",
                    OrderId: order.Id,
                    Lat: order.DropoffLocationLat,
                    Lon: order.DropoffLocationLong,
                    Sequence: idToSequence[order.Id],
                    Time: 0,
                    Address: order.DropoffAddress
                }
                routeTemp.push(event)
                var accRoute = await this.getAccurateRoute(routeTemp)
                
                var color = "gray"
                if(colors.length>0) color=colors[i % colors.length]

                var reqAttribute=[]
                for(var j=0; j<order.RequiredAttributes.length; j++){
                    var attrId = ""+order.RequiredAttributes[j]
                    if(attrToName[attrId]){
                        reqAttribute.push(attrToName[attrId])
                    }else{
                        reqAttribute.push(attrId)
                    }
                }

                //get NonMergedStatistics for order
                var [distance, time] = [0, 0]
                if(route.MergedTrip.NonMergedStatistics){
                    var stats = route.MergedTrip.NonMergedStatistics
                    for(var j=0; j<stats.length; j++){
                        if(stats[j].Id==order.Id){
                            distance = stats[j].Distance
                            time = stats[j].DrivingTime
                        }
                    }
                }
                var trip_new = {
                    Route: routeTemp,
                    AccurateRoute: accRoute,
                    Distance: distance,
                    Time: time,
                    Cost: 0,
                    Color: color,
                    OrderId: order.Id,
                    Sequence: idToSequence[order.Id],
                    ReqAttribute: reqAttribute.join(" ")
                }
                trips_sep.push(trip_new)
            }
        }

        //sort separate routes by sequence
        trips_sep.sort(function(t1,t2){return t1.Sequence-t2.Sequence})
        
        await this.setState(state => ({mainRoute: trips_main,
                                 separateRoutes: trips_sep,
                                 RequestResponse: route}))
    }

    render() {
        if(this.state.TWKeys.length==0){
            this.setTWChoices()
        }
        //var twKeys = Object.keys(timewindows)
        //var exampleKeys = Object.keys(examples)
        
        //<LeafletMap route={this.state.route} routenum={i}
        //                                idToSequence={this.state.idToSequence}
        //                                addSeparateRouteDetails=
        //                                    {this.addSeparateRouteDetails} />
        //<img src="details-icon.png" alt="d"
        //onClick={() =>
        //    this.onDetailsClick(r.OrderId)} />
        // {this.state.detailsId &&
        //     <div class='detailsDiv'>
        //     <DetailsBox route={this.state.route}
        //                 id={this.state.detailsId}
        //                 closeDetails={this.onCloseDetails} />
        //     </div>
        // }
        return (
            <div id='listingDiv'>
                {this.state.SearchBoxVisible>0 &&
                    <div class='searchDiv'>
                    <SearchBox setRoute={this.onSearchBoxSetRoute}
                               closeBox={this.onCloseSearchBoxClick} />
                    </div>
                }

                <div id='selectsDiv'>
                    <div id='selectTWDiv'>
                        <select onChange={e => this.onTWChange(e.target.value)}>
                            <option value="novalue">Select tw</option>
                            {this.state.TWKeys.map(key =>
                            <option value={key}>{timewindows[key].Name}</option>
                            )}
                        </select>
                    </div>
                    <div id='selectRouteDiv'>
                        <select onChange={e => this.onRouteChange(e.target.value)}>
                            {this.state.RoutesList.length > 0 &&
                            <option value="novalue">Select route</option>
                            }
                            {this.state.RoutesList.length == 0 &&
                            <option value="novalue">No routes</option>
                            }
                            {this.state.RoutesList.map(r =>
                            <option value={r.DBId}>{r.Id} ({r.Count}) [{epochToTime(r.Time)}]</option>
                            )}
                        </select>
                    </div>
                    <div id='searchRouteDiv'>
                        <span onClick={() => this.onSearchRouteClick()}>Search by id</span>
                    </div>
                </div>

                <div id='mainRouteDiv'>
                    <div id='mainMapDiv'>
                        <LeafletMap route={this.state.mainRoute} />
                    </div>

                    <StatsMain requestresponse={this.state.RequestResponse} />

                    <div id='mainTripListDiv'>
                        <TripList route={this.state.mainRoute} id={""}/>
                    </div>
                </div>

                <div id='separateAllRoutesMapDiv'>
                    <LeafletMap route={this.state.separateRoutes} />
                </div>
                
                <div id='separateRoutesScrollDiv'>
                <div id='separateRoutesDiv'>
                    {this.state.separateRoutes.length>0 &&
                     this.state.separateRoutes.map((r) =>
                        <div class="separateRouteDiv">
                            <div class="sepIdBarDiv">
                                <div class="sepIdSeq">[{r.Sequence}]</div>
                                <div class="sepIdDiv">{r.OrderId}</div>
                                <div class="detailsIconDiv">
                                    
                                </div>
                            </div>
                            <StatsSeparate
                                route={this.state.route} id={r.OrderId}
                                separateRoutes={this.state.separateRoutes}
                                requestresponse={this.state.RequestResponse} />
                            <div class='sepTripListDiv'>
                                <TripList route={this.state.separateRoutes}
                                          id={r.OrderId} />
                                <div class='sepTripReqAttribute'>
                                    {r.ReqAttribute}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                </div>
            </div>
        )
    }
}